//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SaveDataAdapter from '~/components/v2/adapters/SaveDataAdapter.vue'
export default {
  name: 'AdapterMaskNumberPopUp',
  components: { SaveDataAdapter },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    pText: {
      type: String,
      default: '',
    },
    placeholderNameText: {
      type: String,
      default: '',
    },
    placeholderPhoneText: {
      type: String,
      default: '',
    },
    placeholderEmailText: {
      type: String,
      default: '',
    },
    newsletterText: {
      type: String,
      default: '',
    },
    privacyPolicyText: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },

    wrapperClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    closePopUp() {
      this.$emit('closePopUp')
    },
  },
}
