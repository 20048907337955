//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ApplicationFormSection from '~/components/common/section/ApplicationFormSection.vue'
import { requestSuccessMethods } from '~/plugins/requestSuccess.js'
export default {
  name: 'SaveDataAdapter',

  components: {
    ApplicationFormSection,
  },
  props: {
    crmTags: {
      type: String,
      default: 'CRM_TAGS_V2',
    },
    crmPipeline: {
      type: String,
      default: 'CRM_PIPELINE_V2',
    },
    crmStatus: {
      type: String,
      default: 'CRM_STATUS_V2',
    },
    headerText: {
      type: String,
      default: '',
    },
    pText: {
      type: String,
      default: '',
    },
    placeholderNameText: {
      // не используются-> :placeholder="$getTextMainSetting('nameLabelText')"
      type: String,
      default: '',
    },
    placeholderPhoneText: {
      // не используются-> :placeholder="$getTextMainSetting('telLabelText', 'Tel.')"
      type: String,
      default: '',
    },
    placeholderEmailText: {
      // не используются -> :placeholder="$getTextMainSetting('emailLabelText', 'Email')"
      type: String,
      default: '',
    },
    newsletterText: {
      type: String,
      default: '',
    },
    privacyPolicyText: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    isEmailText: {
      type: Boolean,
    },
    isDetailv2: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isThanksMessageActive: false,
      isErrorMessageActive: false,
      flocktoryEmail: '',
      flocktoryName: '',
    }
  },
  watch: {},
  mounted() {},
  methods: {
    handleSubmit() {
      // this.$store.dispatch('requestSuccess/showPopUp')
      requestSuccessMethods.showPopUp()
      if (this.wrapperClass.includes('pop-up-content')) {
        this.$emit('closePopUp')
      }
    },
    showThanksMessage(data) {
      this.flocktoryEmail = data.email
      this.flocktoryName = data.name
      this.isThanksMessageActive = true
    },
    hideThanksMessage() {
      this.isThanksMessageActive = false
    },
    showErrorMessage() {
      this.isErrorMessageActive = true
    },
    hideErrorMessage() {
      this.isErrorMessageActive = false
    },
  },
}
