import Vue from 'vue'

export const requestSuccessStore = Vue.observable({
  isVisible: false,
})

export const requestSuccessMethods = {
  getVisibility() {
    return requestSuccessStore.isVisible
  },
  setVisibility(visibility) {
    if (typeof visibility === 'boolean') {
      requestSuccessStore.isVisible = visibility
    } else {
      // console.error('Visibility must be a boolean value.')
    }
  },
  showPopUp() {
    this.setVisibility(true)
  },
  hidePopUp() {
    this.setVisibility(false)
  },
  togglePopUp() {
    this.setVisibility(!requestSuccessStore.isVisible)
  },
  updatePopUp(visibility) {
    this.setVisibility(visibility)
  },
}
